















































































import { Component, Vue } from 'vue-property-decorator'
import type { Team } from '@tada-team/tdproto-ts'
import {
  loginStore,
  teamsStore,
  uiSettingsStore,
} from '@/store'

@Component({
  name: 'TheTeamsView',
  components: {
    ContentHeader: () => import('./components/ContentHeader.vue'),
  },
})
export default class TheTeamsView extends Vue {
  loading = false

  /**
   * @returns count of all needConfirmation teams and +1 if has invite token in cookies
   */
  get invitationsCount () {
    let invites = teamsStore.getters.teams.filter(
      (team: Team) => team.needConfirmation,
    ).length
    const token = this.$q.cookies.get('invitation_token')
    token && invites++
    return invites - loginStore.state.acceptedInvitesUids.length
  }

  /**
   * Find current active team in case if user already create team but not join to it
   * Or find another existing team that not need to be confirmed invites
   * @returns team for openning or undefined
   */
  get teamToOpen (): Team | undefined {
    let team: Team | undefined

    try {
      team = teamsStore.getters.currentTeam
    } catch (e) {
      const teams: Array<Team> = teamsStore.getters.teams
      team = teams.find(t => !t.needConfirmation)
    }

    return team
  }

  /**
   * If has invitations - GoToInvitations button is primary and CreateTeam is secondary
   * Else conversely
   */
  buttonClass (isCreateTeam = false) {
    let btnClass = 'text-td-blue-3 bg-td-gray-25'
    if (
      (isCreateTeam && !this.invitationsCount) ||
      (!isCreateTeam && this.invitationsCount)
    ) {
      btnClass = 'bg-td-blue-3 text-white'
    }
    return btnClass
  }

  /**
   * Navigate to invitations screen
   */
  goToInvitations () {
    window.goal('onboardingAction', { onboarding: 'Управление командами — «Приглашения»' })
    this.$router.push({ name: 'Invitations' })
  }

  /**
   * Navigate to creating team screen
   */
  goToCreateTeam () {
    this.$router.push({ name: 'CreateTeam' })
  }

  /**
   * Set current team and opened it in main view
   */
  goToTeam () {
    window.goal('onboardingAction', { onboarding: 'Управление командами — «Перейти в созданную команду»' })
    uiSettingsStore.actions.initTeam({ teamId: this.teamToOpen?.uid ?? '' })
    teamsStore.actions.initTeam(this.teamToOpen?.uid ?? '')

    this.$router.push({ name: 'Main' })
  }
}
